









































































































































































































































.set-container {
    margin: 20px;
    background: #fff;
    height: calc(100% - 40px);
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
}
.tab-box {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}
.banner-box {
    display: inline-block;
    /*width: 100%;*/
    width: 119.117647px;
    height: 0;
    /*padding-bottom: 67.1604938%;*/
    padding-bottom: 80px;
    position: relative;
    background: #f5f5f5;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
    }
}

::v-deep .img-uploader {
    width: 100%;
    height: 0;
    padding-bottom: 67.1604938%;
    background: #f5f5f5;
    position: relative;
    .el-upload {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .quickTalk-img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .add-box {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #999;
            .add-icon {
                font-size: 30px;
            }
            .add-text {
                font-size: 24px;
            }
        }
    }
}
::v-deep .banner-list {
    width: 405px;
    height: 272px;
    background: #f5f5f5;
    margin: 0 auto;
    .el-carousel__container {
        height: 272px;
    }
    .banner-item {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
